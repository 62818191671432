<!-- Titre -->
<div class="page-title">
	{{ 'abonnes.abonnement_label' | translate }}
</div>

<p-panel class="no-header-panel">

	<div class="p-fluid grid formgrid">
		<!-- Date d'expiration de l'abonnement -->
		<div class="field col-12 md:col-2">
			<label for="abo_nom_societe">
				{{ 'abonnes.expiration_date_label' | translate }}
			</label>
			<input
				type="text"
				pInputText
				[attr.value]="abonne.abo_date_expiration | date:'shortDate'"
				[readonly]="true"
			>
		</div>

		<div class="col-12"></div>

		<!-- Nombre max d'utilisateurs -->
		<div class="field col-12 md:col-3">
			<label for="abo_nombre_utilisateurs">{{ 'abonnes.users_max_number_label' | translate }}</label>
			<input
				type="text"
				pInputText
				gzInputNumberFormatter
				name="abo_nombre_utilisateurs"
				[(ngModel)]="abonne.abo_nombre_utilisateurs"
				class="text-right"
				[readonly]="true"
			>
		</div>

		<!-- Nombre d'utilisateurs actifs -->
		<div class="field col-12 md:col-3">
			<label>{{ 'abonnes.active_user_number_label' | translate }}</label>
			<input
				type="text"
				pInputText
				gzInputNumberFormatter
				name="nombre_utilisateurs_actifs"
				[(ngModel)]="abonne.nombre_utilisateurs_actifs"
				class="text-right"
				[ngClass]="{
					'text-color-danger font-bold': abonne.abo_nombre_utilisateurs != null && abonne.nombre_utilisateurs_actifs > abonne.abo_nombre_utilisateurs
				}"
				[readonly]="true"
			>
		</div>

		<p-message
			severity="warn"
			text="{{'abonnes.max_user_number_exceeded' | translate}}"
			*ngIf="abonne.abo_nombre_utilisateurs != null && abonne.nombre_utilisateurs_actifs > abonne.abo_nombre_utilisateurs"
			class="mb-2"
		></p-message>

		<div class="col-12"></div>

		<!-- Nombre max de sites -->
		<div class="field col-12 md:col-3">
			<label for="abo_nombre_sites">
				{{ 'abonnes.sites_max_number_label' | translate }}
			</label>
			<input
				type="text"
				pInputText
				gzInputNumberFormatter
				name="abo_nombre_sites"
				[(ngModel)]="abonne.abo_nombre_sites"
				class="text-right"
				[readonly]="true"
			>
		</div>

		<!-- Nombre de sites actifs -->
		<div class="field col-12 md:col-3">
			<label>{{ 'abonnes.active_site_number_label' | translate }}</label>
			<input
				type="text"
				pInputText
				gzInputNumberFormatter
				name="nombre_sites_actifs"
				[(ngModel)]="abonne.nombre_sites_actifs"
				class="text-right"
				[ngClass]="{
					'text-color-danger font-bold': abonne.abo_nombre_sites != null && abonne.nombre_sites_actifs > abonne.abo_nombre_sites
				}"
				[readonly]="true"
			>
		</div>

		<p-message
			severity="warn"
			text="{{'abonnes.max_site_number_exceeded' | translate}}"
			*ngIf="abonne.abo_nombre_sites != null && abonne.nombre_sites_actifs > abonne.abo_nombre_sites"
		></p-message>

		<div class="col-12"></div>

		<div class="field col-12">
			<label>{{ 'families.families_included_in_subscription' | translate }}</label>

			<ul class="flex flex-column m-0 pl-4">
				<li *ngFor="let famille of abonne.familles" class="mr-2">
					{{famille.fam_nom}}
				</li>
			</ul>
		</div>
	</div>

</p-panel>
