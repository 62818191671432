import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { StateService, UIRouterModule } from '@uirouter/angular';

import { TranslateService } from '@codeandweb/ngx-translate';

import { CalendarModule } from '@app/primeng-overrides/calendar';
import { MenuModule } from '@app/primeng-overrides/menu';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { PanelModule } from 'primeng/panel';
import { ToolbarModule } from 'primeng/toolbar';

import { EventManagerService } from '@app/_global/event-manager.service';
import { Abonne } from '@app/abonne/abonne.model';
import { AbonneService } from '@app/abonne/abonne.service';
import { Site } from '@app/site/site.model';
import { SiteService } from '@app/site/site.service';
import { CiviliteSelectorModule } from '@app/utilisateur/civilite-selector';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { UtilisateurService } from '@app/utilisateur/utilisateur.service';
import { GlobalModule } from '@global/global.module';
import { firstValueFrom } from 'rxjs';


@Component({
	selector: 'abonne-creation',
	templateUrl: './abonne-creation.component.html'
})
export class AbonneCreationComponent  {

	@Input() me: Utilisateur;

	abonne: Abonne;
	gestionnaireAbonnement: Utilisateur;
	firstSite: Site;

	submitted = false;

	constructor(
		private stateService: StateService,
		private abonneService: AbonneService,
		private utilisateurService: UtilisateurService,
		private siteService: SiteService,
		private eventManager: EventManagerService,
		private translate: TranslateService
	) {
		this.abonne = {
			utilisateurs: [],
			abo_actif: true,
		} as unknown as Abonne;

		this.gestionnaireAbonnement = {
			uti_actif: true,
			uti_bloque: false
		} as Utilisateur;

		this.firstSite = {
			sit_actif: true,
			sit_libelle: translate.instant('sites.default_name')
		} as Site;

	}

	submit(){
		this.submitted = true;
		this.abonneService.createAbonne(this.abonne)
		.subscribe({
			next: async ({ abo_id }: { abo_id: number}) => {
				let toastLifeMilliseconds = 5000;
				this.eventManager.emit('toast', {
					severity: 'success',
					summary: this.translate.instant('abonnes.abonne_created_success'),
					life: toastLifeMilliseconds
				});

				// Création du gestionnaire d'abonnement
				this.utilisateurService.createUtilisateur(this.gestionnaireAbonnement, abo_id, true).subscribe(() => {
					this.eventManager.emit('toast', {
						severity: 'success',
						summary: this.translate.instant('users.subscription_manager_created_success'),
						life: toastLifeMilliseconds
					});
				});

				// Création du site
				await firstValueFrom(this.siteService.createSite(abo_id, this.firstSite));
				this.eventManager.emit('toast', {
					severity: 'success',
					summary: this.translate.instant('sites.site_created_success'),
					life: toastLifeMilliseconds
				});

				this.abonneService.currentAbonneValue = null;
 				this.stateService.go('abonne-courant', {curr_abo_id: abo_id}).then(() => {
					this.stateService.go('admin.abonnes.single', {abo_id: abo_id});
				});
			}
		});
	}

}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		FormsModule,
		ButtonModule,
		ButtonModule,
		CheckboxModule,
		DividerModule,
		InputTextModule,
		MenuModule,
		MessageModule,
		PanelModule,
		ToolbarModule,
		CalendarModule,
		CiviliteSelectorModule
	],
	exports: [AbonneCreationComponent],
	declarations: [AbonneCreationComponent]
})
export class AbonneModule { }
