import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { StateService, UIRouterModule } from '@uirouter/angular';

import { ButtonModule } from 'primeng/button';
import { CalendarModule } from '@app/primeng-overrides/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from '@app/primeng-overrides/inputtext';
import { MenuModule } from '@app/primeng-overrides/menu';
import { MessageModule } from 'primeng/message';
import { PanelModule } from 'primeng/panel';
import { TableModule } from '@app/primeng-overrides/table';
import { ToolbarModule } from 'primeng/toolbar';

import { Abonne } from '@app/abonne/abonne.model';
import { AbonneService } from '@app/abonne/abonne.service';
import { EventManagerService } from '@app/_global/event-manager.service';
import { GlobalModule } from '@global/global.module';
import { GzTabMenuModule } from '@app/components/tabmenu';
import { LogoUploaderModule } from '@app/abonne/abonne-logo-uploader';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';


@Component({
	selector: 'abonne-infos-tab',
	templateUrl: `abonne-infos-tab.component.html`
})
export class AbonneInfosTabComponent implements OnInit {

	@Input() me: Utilisateur;
	@Input() abonne: Abonne;

	initialAbonne: Abonne;

	canEdit = false;
	editMode = false;

	constructor(
		private stateService: StateService,
		private abonneService: AbonneService,
		private eventManager: EventManagerService,
	) {}

	ngOnInit(): void {
		this.canEdit = this.me.uti_administrateur;
		this.initialAbonne = structuredClone(this.abonne);
	}

	toggleEditMode(status?: boolean) {
		this.editMode = (status !== undefined)? status : !!!this.editMode;
	}

	submit(){
		this.abonneService.updateAbonne(this.abonne)
		.subscribe({
			next: () => {
				this.eventManager.emit('toast', { severity: 'success', summary: 'Abonné modifié' });
				this.stateService.go('.', undefined, {reload: '^'});
			}
		})
	}
}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		FormsModule,
		ButtonModule,
		ButtonModule,
		CalendarModule,
		CheckboxModule,
		DividerModule,
		InputTextModule,
		MenuModule,
		MessageModule,
		PanelModule,
		ToolbarModule,
		LogoUploaderModule,
		GzTabMenuModule,
	],
	exports: [AbonneInfosTabComponent],
	declarations: [AbonneInfosTabComponent]
})
export class AbonneInfosTabModule { }
