<p-panel>

	<ng-template pTemplate="header">
		<div class="flex flex-row align-items-center">

			<!-- Titre -->
			<div class="p-panel-title mr-3 ml-1">
				{{ 'abonnes.new_abonne' | translate }}
			</div>

		</div>
	</ng-template>

	<ng-template pTemplate="icons">
		<!-- Bouton enregistrer -->
		<button
			type="button"
			pButton
			class="label-md-none"
			[label]="'common.save_button_label' | translate"
			icon="pi pi-save"
			(click)="submit()"
			[disabled]="!abonneForm.valid"
		></button>
	</ng-template>

	<form class="mb-2" #abonneForm="ngForm">

		<div class="grid formgrid p-fluid">

			<!-- Information principales (ligne du haut)-->
			<div class="grid col-12 mb-2">
				<!-- Nom abonné -->
				<div class="field col-12 lg:col-6 xl:col-3">
					<label for="abo_nom_abonne">
						{{ 'abonnes.abonne_name_label' | translate }}
					</label>
					<input
						type="text"
						pInputText
						name="abo_nom_abonne"
						[(ngModel)]="abonne.abo_nom_abonne"
						placeholder="{{'abonnes.abonne_name_label'|translate}}"
						required
					/>
				</div>

				<!-- Date d'expiration de l'abonnement -->
				<div class="field col-12 lg:col-6 xl:col-3">
					<label for="abo_nom_societe">
						{{ 'abonnes.expiration_date_label' | translate }}
					</label>
					<p-calendar
						name="abo_date_expiration"
						[(ngModel)]="abonne.abo_date_expiration"
						dateFormat="dd/mm/yy"
						firstDayOfWeek="1"
						view="date"
						yearRange="2020:2030"
						[showIcon]="true"
						[placeholder]="'common.day_month_year_date_placeholder' | translate"
						required
					></p-calendar>
				</div>
			</div>

			<div class="grid col-12 mt-2">
				<p-divider class="col-12" align="left">
					<b>{{ 'users.subscription_manager_role_name' | translate }}</b>
				</p-divider>

				<!-- Email -->
				<div class="field col-12 lg:col-6 xl:col-3">
					<label for="uti_email">
						{{ 'common.email_label' | translate }}
					</label>
					<input
						type="email"
						pInputText
						name="uti_email"
						#uti_email="ngModel"
						[(ngModel)]="gestionnaireAbonnement.uti_email"
						email="true"
						[ngClass]="{ 'p-invalid': submitted && uti_email.errors }"
						placeholder="email@exemple.com"
						required
					>
				</div>

				<!-- Civilité -->
				<div class="field col-12 lg:col-6 xl:col-3">
					<label for="uti_civilite">
						{{ 'users.salutation_label' | translate }}
					</label>

					<civilite-selector
						name="uti_civilite"
						#uti_civilite
						[(ngModel)]="gestionnaireAbonnement.uti_civilite"
						required
					></civilite-selector>
				</div>

				<!-- Nom de famille -->
				<div class="field col-12 lg:col-6 xl:col-3">
					<label for="uti_nom">
						{{ 'users.last_name_label' | translate }}
					</label>

					<input
						type="text"
						pInputText
						name="uti_nom"
						#uti_nom="ngModel"
						[(ngModel)]="gestionnaireAbonnement.uti_nom"
						[ngClass]="{ 'p-invalid': submitted && uti_nom.errors }"
						placeholder="{{'users.last_name_label' | translate}}"
						required
					>
				</div>

				<!-- Prénom -->
				<div class="field col-12 lg:col-6 xl:col-3">
					<label for="uti_prenom">
						{{ 'users.first_name_label' | translate }}
					</label>

					<input
						type="text"
						pInputText
						name="uti_prenom"
						#uti_prenom="ngModel"
						[(ngModel)]="gestionnaireAbonnement.uti_prenom"
						required
						[ngClass]="{ 'p-invalid': submitted && uti_prenom.errors }"
						placeholder="{{'users.first_name_label' | translate}}"
					>
				</div>

				<p-divider class="col-12 mt-4" align="left">
					<b>{{ 'sites.first_site' | translate }}</b>
				</p-divider>

				<!-- Nom du site -->
				<div class="field col-12 lg:col-6 xl:col-3">
					<label for="sit_libelle">
						{{ 'sites.site_name_label' | translate }}
					</label>
					<input
						type="text"
						pInputText
						name="sit_libelle"
						[(ngModel)]="firstSite.sit_libelle"
						placeholder="{{'sites.site_name_label' | translate}}"
						required
					/>
				</div>
			</div>
		</div>
	</form>

</p-panel>
