<p-panel>

	<ng-template pTemplate="header">
		<div class="flex align-items-center">

			<!-- Titre -->
			<div class="p-panel-title mr-3 ml-1">
				<ng-container *ngIf="isCreation">
					{{ 'sites.new_site' | translate }}
				</ng-container>

				<ng-container *ngIf="!isCreation">
					{{ initialSite.sit_libelle }}
				</ng-container>
			</div>

			<!-- Checkbox actif/inactif -->
			<div class="field-checkbox mb-0">
				<p-checkbox
					name="sit_actif"
					inputId="sit_actif"
					[(ngModel)]="site.sit_actif"
					[binary]="true"
					inputId="binary"
					[label]="site.sit_actif ? ('sites.active_state' | translate) : ('sites.inactive_state' | translate)"
					[disabled]="!editMode"
				></p-checkbox>
			</div>
		</div>
	</ng-template>

	<ng-template pTemplate="icons">
		<!-- Bouton éditer -->
		<button
			*ngIf="!editMode && canEditSite"
			type="button"
			pButton
			class="label-md-none"
			[label]="'common.edition_button_label' | translate"
			icon="pi pi-pencil"
			(click)="toggleEditMode()"
		></button>

		<!-- Bouton enregistrer -->
		<button
			*ngIf="!!editMode"
			type="button"
			pButton
			class="label-md-none"
			[label]="'common.save_button_label' | translate"
			icon="pi pi-save"
			(click)="submit()"
			[disabled]="!siteForm.valid"
		></button>
	</ng-template>


	<div class="flex flex-column">
		<form class="mb-2" #siteForm="ngForm">
			<div class="p-fluid grid formgrid p-2 col-12">

				<div class="col-12"></div>

				<!-- Nom du site -->
				<div class="field col-3">
					<label for="sit_libelle">
						{{ 'sites.site_name_label' | translate }}
					</label>
					<input
						type="text"
						pInputText
						name="sit_libelle"
						[(ngModel)]="site.sit_libelle"
						placeholder="{{'sites.site_name_label' | translate}}"
						[readonly]="!editMode"
						required
					/>
				</div>

				<p-divider class="col-12 mt-4" align="left">
					<b>{{ 'common.address_label' | translate }}</b>
				</p-divider>

				<!-- Nom société (raison sociale) -->
				<div class="field col-3">
					<label for="sit_nom_societe">
						{{ 'common.company_name_label' | translate }}
					</label>
					<input
						type="text"
						pInputText
						name="sit_nom_societe"
						[(ngModel)]="site.sit_nom_societe"
						placeholder="{{'common.company_name_label' | translate}}"
						[readonly]="!editMode"
					/>
				</div>

				<!-- Adresse 1 -->
				<div class="field col-3">
					<label for="sit_adresse_1">
						{{ 'sites.address_1_label' | translate }}
					</label>
					<input
						type="text"
						pInputText
						name="address_1_label"
						[(ngModel)]="site.sit_adresse_1"
						placeholder="{{'sites.address_1_label' | translate}}"
						[readonly]="!editMode"
					/>
				</div>

				<!-- Adresse 2 -->
				<div class="field col-3">
					<label for="sit_adresse_2">
						{{ 'sites.address_2_label' | translate }}
					</label>
					<input
						type="text"
						pInputText
						name="address_2_label"
						[(ngModel)]="site.sit_adresse_2"
						placeholder="{{'sites.address_2_label' | translate}}"
						[readonly]="!editMode"
					/>
				</div>

				<!-- Adresse 3 -->
				<div class="field col-3">
					<label for="sit_adresse_3">
						{{ 'sites.address_3_label' | translate }}
					</label>
					<input
						type="text"
						pInputText
						name="address_3_label"
						[(ngModel)]="site.sit_adresse_3"
						placeholder="{{'sites.address_3_label' | translate}}"
						[readonly]="!editMode"
					/>
				</div>

				<div class="col-12"></div>

				<!-- Code postal -->
				<div class="field col-3">
					<label for="sit_code_postal">
						{{ 'common.postal_code_label' | translate }}
					</label>
					<input
						type="text"
						pInputText
						name="sit_code_postal"
						[(ngModel)]="site.sit_code_postal"
						placeholder="{{'common.postal_code_label' | translate}}"
						[readonly]="!editMode"
					/>
				</div>

				<!-- Ville -->
				<div class="field col-3">
					<label for="sit_ville">
						{{ 'common.city_label' | translate }}
					</label>
					<input
						type="text"
						pInputText
						name="sit_ville"
						[(ngModel)]="site.sit_ville"
						placeholder="{{'common.city_label'|translate}}"
						[readonly]="!editMode"
					/>
				</div>

				<!-- Pays -->
				<div class="field col-3">
					<label for="sit_pays">
						{{ 'common.country_label' | translate }}
					</label>

					<input
						type="text"
						pInputText
						name="sit_pays"
						[(ngModel)]="site.sit_pays"
						placeholder="{{'common.country_label' | translate}}"
						[readonly]="!editMode"
					/>
				</div>

				<p-divider class="col-12 mt-4" align="left">
					<b>{{ 'common.contact_label' | translate }}</b>
				</p-divider>

				<!-- Téléphone -->
				<div class="field col-3">
					<label for="abo_telephone">
						{{ 'common.phone_label' | translate }}
					</label>

					<input
						type="text"
						pInputText
						name="abo_telephone"
						[(ngModel)]="site.sit_telephone"
						placeholder="{{'common.phone_label' | translate}}"
						[readonly]="!editMode"
					/>
				</div>

				<!-- Email -->
				<div class="field col-3">
					<label for="abo_email">
						{{ 'common.email_label' | translate }}
					</label>

					<input
						type="text"
						pInputText
						name="abo_email"
						[(ngModel)]="site.sit_email"
						placeholder="{{'common.email_label' | translate}}"
						[readonly]="!editMode"
					/>
				</div>
			</div>
		</form>

	</div>
</p-panel>
