import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';

import { UIRouterModule } from '@uirouter/angular';

import { StateService } from '@uirouter/core';

import { ButtonModule } from 'primeng/button';

import { AuthService } from '@app/auth/auth.service';
import { EventManagerService } from '@global/event-manager.service';
import { GlobalModule } from './global.module';

@Component({
	template: `
		<div class="landing flex-column justify-content-center align-items-center vertical-container relative">
			<img src="/assets/images/logo.png" class="mb-5 logo-xl">

			<p *ngIf="!error">
				{{ 'availability.service_momentarily_unavailable' | translate }}
			</p>

			<ng-container *ngIf="error">
				<ng-container [ngSwitch]="error">

					<p
						*ngSwitchCase="'maintenance'" class="text-center"
						[innerHTML]="'availability.maintenance_operation_in_progress' | translate">
					</p>

					<p *ngSwitchDefault>
						{{ 'availability.this_should_not_have_happened' | translate }}
					</p>

				</ng-container>
			</ng-container>


			<a href="/">
				<button
					type="button"
					pButton
					class="mb-3"
					[label]="'availability.go_back_to_home_page' | translate"
				></button>
			</a>

			<button
				type="button"
				pButton
				class="p-button-sm p-button-text"
				[label]="'availability.disconnect' | translate"
				(click)="logout()"
				*ngIf="loggedIn"
			></button>

		</div>
	`
})
export class ServiceUnavailableComponent implements OnInit {

	@Input() error: any;

	loggedIn: boolean = false;

	constructor(
		private authService: AuthService,
		private eventManager: EventManagerService,
		private stateService: StateService,
	) { }

	ngOnInit() {
		if (!this.error) {
			this.error = this.stateService.params.error;
		}
		this.loggedIn = !!this.authService.token;
	}

	logout() {
		this.eventManager.emit('logout', true);
	}

}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		ButtonModule,
	],
	exports: [ServiceUnavailableComponent],
	declarations: [ServiceUnavailableComponent]
})
export class ServiceUnavailableModule { }
